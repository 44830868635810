import React from 'react';

export default function DownloadsContainer () { 
	return(
		<>
			<section className="l-section">
    <div className="container">
      <div className="l-section__header">
        <div className="row w-100">
          <div className="col-12 col-sm-auto">
            <h2 className="h3 bold l-section__title">My Downloads</h2>
          </div>
          <div className="col">
            <div className="dropdown ms-sm-8">
              <label>Sort:</label>
              <a className="c-link dropdown-toggle" href="#" role="button" id="dropdownMenuRecommended" data-bs-toggle="dropdown" aria-expanded="false">
                <div className="c-link__text">Dropdown</div>
                <div className="c-link__arrow">
                  <svg className="c-icon-fill"><use href="../../../assets/icons/sprite-icons.svg#icon-arrow-down"/></svg>
                </div>
              </a>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuRecommended">
                <li><a className="dropdown-item" href="#">Option 01</a></li>
                <li><a className="dropdown-item" href="#">Option 02</a></li>
                <li><a className="dropdown-item" href="#">option 03</a></li>
              </ul>
            </div>
          </div>
          <div className="col-auto">
            <a className="c-link" href="#">
              <div className="c-link__text">Link</div>
              <div className="c-link__arrow">
                <svg className="c-link__arrow__line"><line x1="0" y1="0" x2="36" y2="0" stroke="black" /></svg>
                <svg className="c-icon-fill"><use href="../../../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="row g-5 row-cols-xs-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4" >
        
        <div className="col">
          <div className="c-card-assets c-card-assets--light">
    <div className="c-card-assets__media">
        <img src={require("../../../assets/images/card-news.jpg")} alt="" />
        <div className="c-card-assets__header">
            <span className="c-pill c-pill--secondary">Pill</span>
            <div className="c-save c-save--white active">
                <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-bookmark-asset-v1"/></svg>
            </div>
        </div>
    </div>
    <div className="c-card-assets__content">   
        <div className="c-card-assets__body">
            <div className="c-signature_1">Katy Hoffman</div>
            <h5 className="c-card-assets__title">Havas Group Credentials Paris 2022</h5>
            <div className="c-data">Created: <span>Jun 18, 2022</span></div>
        </div>
        <div className="c-card-assets__footer">
            <div className="d-flex mb-2">
                <button type="button" className="c-btn c-btn-outline--primary c-btn-icon">
                    Detail
                    <svg className="c-icon-fill"><use href="../../../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                </button>
                <button type="button" className="c-btn c-btn-outline--primary c-btn-icon">
                    Open
                    <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-open-v1"/></svg>
                </button>
            </div>
            <button type="button" className="c-btn c-btn--primary c-btn-icon c-btn-icon__center w-100">
                <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-download-v1"/></svg>
                Download
            </button>
        </div>
    </div>
</div>
        </div>
        
        <div className="col">
          <div className="c-card-assets c-card-assets--light">
    <div className="c-card-assets__media">
        <img src={require("../../../assets/images/card-news.jpg")} alt="" />
        <div className="c-card-assets__header">
            <span className="c-pill c-pill--secondary">Pill</span>
            <div className="c-save c-save--white active">
                <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-bookmark-asset-v1"/></svg>
            </div>
        </div>
    </div>
    <div className="c-card-assets__content">   
        <div className="c-card-assets__body">
            <div className="c-signature_1">Katy Hoffman</div>
            <h5 className="c-card-assets__title">Havas Group Credentials Paris 2022</h5>
            <div className="c-data">Created: <span>Jun 18, 2022</span></div>
        </div>
        <div className="c-card-assets__footer">
            <div className="d-flex mb-2">
                <button type="button" className="c-btn c-btn-outline--primary c-btn-icon">
                    Detail
                    <svg className="c-icon-fill"><use href="../../../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                </button>
                <button type="button" className="c-btn c-btn-outline--primary c-btn-icon">
                    Open
                    <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-open-v1"/></svg>
                </button>
            </div>
            <button type="button" className="c-btn c-btn--primary c-btn-icon c-btn-icon__center w-100">
                <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-download-v1"/></svg>
                Download
            </button>
        </div>
    </div>
</div>
        </div>
        
        <div className="col">
          <div className="c-card-assets c-card-assets--light">
    <div className="c-card-assets__media">
        <img src={require("../../../assets/images/card-news.jpg")} alt="" />
        <div className="c-card-assets__header">
            <span className="c-pill c-pill--secondary">Pill</span>
            <div className="c-save c-save--white active">
                <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-bookmark-asset-v1"/></svg>
            </div>
        </div>
    </div>
    <div className="c-card-assets__content">   
        <div className="c-card-assets__body">
            <div className="c-signature_1">Katy Hoffman</div>
            <h5 className="c-card-assets__title">Havas Group Credentials Paris 2022</h5>
            <div className="c-data">Created: <span>Jun 18, 2022</span></div>
        </div>
        <div className="c-card-assets__footer">
            <div className="d-flex mb-2">
                <button type="button" className="c-btn c-btn-outline--primary c-btn-icon">
                    Detail
                    <svg className="c-icon-fill"><use href="../../../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                </button>
                <button type="button" className="c-btn c-btn-outline--primary c-btn-icon">
                    Open
                    <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-open-v1"/></svg>
                </button>
            </div>
            <button type="button" className="c-btn c-btn--primary c-btn-icon c-btn-icon__center w-100">
                <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-download-v1"/></svg>
                Download
            </button>
        </div>
    </div>
</div>
        </div>
        
        <div className="col">
          <div className="c-card-assets c-card-assets--light">
    <div className="c-card-assets__media">
        <img src={require("../../../assets/images/card-news.jpg")} alt="" />
        <div className="c-card-assets__header">
            <span className="c-pill c-pill--secondary">Pill</span>
            <div className="c-save c-save--white active">
                <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-bookmark-asset-v1"/></svg>
            </div>
        </div>
    </div>
    <div className="c-card-assets__content">   
        <div className="c-card-assets__body">
            <div className="c-signature_1">Katy Hoffman</div>
            <h5 className="c-card-assets__title">Havas Group Credentials Paris 2022</h5>
            <div className="c-data">Created: <span>Jun 18, 2022</span></div>
        </div>
        <div className="c-card-assets__footer">
            <div className="d-flex mb-2">
                <button type="button" className="c-btn c-btn-outline--primary c-btn-icon">
                    Detail
                    <svg className="c-icon-fill"><use href="../../../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                </button>
                <button type="button" className="c-btn c-btn-outline--primary c-btn-icon">
                    Open
                    <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-open-v1"/></svg>
                </button>
            </div>
            <button type="button" className="c-btn c-btn--primary c-btn-icon c-btn-icon__center w-100">
                <svg className="c-icon-stroke"><use href="../../../assets/icons/sprite-icons.svg#icon-download-v1"/></svg>
                Download
            </button>
        </div>
    </div>
</div>
        </div>
        
      </div>
        
    </div>
  </section>
		</>

		)

}