import React from 'react';

export default function NewsContainer () { 
	return(
		<>
		<section class="l-section l-section--light">
  <div class="container">
    <div class="l-section__header">
      <h2 class="h3 bold l-section__title">Latest News</h2>
      <a class="c-link" href="#">
        <div class="c-link__text">Link</div>
        <div class="c-link__arrow">
          <svg class="c-link__arrow__line"><line x1="0" y1="0" x2="36" y2="0" stroke="black" /></svg>
          <svg class="c-icon-fill"><use href="../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
        </div>
      </a>
    </div>
    <div class="row g-5 g-xxl-10">
      <div class="col-8">
        <div class="c-card-featured">
    <div class="c-card-featured__media">
        <img src={require("../../../assets/images/card-feature.jpg")} alt="" />
    </div>
    <div class="c-card-featured__content">
        <div class="c-card-featured__header">
            <span class="c-pill c-pill--yellow-100">Pill</span>
            <div class="c-save c-save--white">
                <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-bookmark-asset-v1"/></svg>
            </div>
        </div>
        <div class="c-card-featured__body">
            <div class="c-data-signature">
                Jun 18, 2022 - <a href="#">Campaign UK</a>
            </div>
            <h4 class="c-card-featured__title">Matalan appoints Havas Media Group following three-way pitch</h4>
        </div>
        <div class="c-card-featured__footer">
            <a class="c-link" href="#">
                <div class="c-link__text">Read more</div>
                <div class="c-link__arrow">
                  <svg class="c-icon-fill"><use href="../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                </div>
            </a>
        </div>
    </div>
</div>
      </div>
      <div class="col-4">
        <div class="c-card-news">
    <div class="c-card-news__media">
        <img src={require("../../../assets/images/card-news.jpg")} alt="" />
        <div class="c-card-news__header">
            <span class="c-pill c-pill--secondary">Pill</span>
            <div class="c-save c-save--white">
                <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-bookmark-asset-v1"/></svg>
            </div>
        </div>
    </div>
    <div class="c-card-news__content">   
        <div class="c-card-news__body">
            <div class="c-data-signature">
                Jun 18, 2022 - <a href="#">Campaign UK</a>
            </div>
            <h4 class="c-card-news__title">Matalan appoints Havas Media Group following three-way pitch</h4>
        </div>
        <div class="c-card-news__footer">
            <a class="c-link" href="#">
                <div class="c-link__text">Read more</div>
                <div class="c-link__arrow">
                  <svg class="c-icon-fill"><use href="../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                </div>
            </a>
        </div>
    </div>
</div>
      </div>
    </div>
  </div>
</section>
	</>
		)

}