import React from 'react';

//components
import HeaderContainer from './header.jsx';
import FooterContainer from '../../shared/layout/footer/index.jsx';
import NavbarContainer from '../../shared/layout/navbar/index.jsx';
import AssetsWeekContainer from './assets-week.jsx';
import DownloadsContainer from './downloads.jsx';
import NewsContainer from './news.jsx';
import LibraryContainer from './library.jsx';
import NewsLetterContainer from './newsletter.jsx';

export default function HomeComponent () { 
	return(
		<>
			<div class="page-home">
				<NavbarContainer />
				<HeaderContainer />
				<NewsContainer />
				<LibraryContainer />
				<AssetsWeekContainer />
				<DownloadsContainer />
				<NewsLetterContainer />
				<FooterContainer />	
			</div>		
		</>
		

		)

}