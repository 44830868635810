import React from 'react';

//components


export default function FooterContainer () { 
	return(
		<>
		<div class="l-footer">
    <div class="row g-6 g-sm-10 g-xxl-4">
        <div class="col-xl-6">
            <div class="row">
                <div class="col">
                    <div class="l-footer__nav l-footer__nav--lg">
                        <a class="l-footer__item" href="#">News & communications</a>
                        <a class="l-footer__item" href="#">Havas Group info</a>
                        <a class="l-footer__item" href="#">Research & Client work</a>
                        <a class="l-footer__item" href="#">Applications</a>
                        <a class="l-footer__item" href="#">Forum</a>
                        <a class="l-footer__item" href="#">My documents</a>
                    </div>
                </div>
                <div class="col-5 d-none d-sm-block">
                    <div class="l-footer__nav">
                        <a class="l-footer__item" href="#">Meaningful brands</a>
                        <a class="l-footer__item" href="#">Dare Havas</a>
                        <a class="l-footer__item" href="#">Risk management</a>
                        <a class="l-footer__item" href="#">Havas University</a>
                        <a class="l-footer__item" href="#">Meaningful Media</a>
                        <a class="l-footer__item" href="#">Prosumer Reports</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="row g-6">
                <div class="col col-xl-5 order-2 order-sm-1 order-xl-2 d-flex flex-column justify-content-between">
                    <div class="l-footer__support">
                        <div><strong>Agora support</strong></div>
                        <p>Do you have any questions?<br /> Please <a href="#">contact our team!</a></p>
                    </div>
                    <div class="d-xl-none d-flex flex-column">
                        <a class="c-link c-link--sm" href="#">
                            <div class="c-link__text">Terms of use</div>
                            <div class="c-link__arrow">
                              <svg class="c-icon-fill"><use href="../../../../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                            </div>
                        </a>
                        <a class="c-link c-link--sm" href="#">
                            <div class="c-link__text">Cookie settings</div>
                            <div class="c-link__arrow">
                              <svg class="c-icon-fill"><use href="../../../../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                            </div>
                        </a>
                        <a class="c-link c-link--sm" href="#">
                            <div class="c-link__text">www.havasgroup.com</div>
                            <div class="c-link__arrow">
                              <svg class="c-icon-fill"><use href="../../../../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-5 col-xl order-1 order-sm-2 order-xl-1">
                    <div class="l-footer__nav l-footer__nav--lg">
                        <a class="l-footer__item" href="#">FAQ</a>
                        <a class="l-footer__item" href="#">User Guides</a>
                        <a class="l-footer__item" href="#">Tutorials</a>
                        <a class="l-footer__item" href="#">User Maintenance</a>
                        <a class="l-footer__item" href="#">Contact</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="l-footer__bottom">
        <div class="row">
            <div class="col-xl-6">
                <div class="row">
                    <div class="col"><small>Copyright 2022 © AGORA</small></div>
                    <div class="col-5"><small>All rights reserved</small></div>
                </div>
            </div>
            <div class="col-6 d-none d-xl-block">
                <div class="row">
                    <div class="col d-flex">
                        <a class="c-link c-link--sm" href="#">
                            <div class="c-link__text">Terms of use</div>
                            <div class="c-link__arrow">
                              <svg class="c-icon-fill"><use href="../../../../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                            </div>
                        </a>
                        <a class="c-link c-link--sm ms-3" href="#">
                            <div class="c-link__text">Cookie settings</div>
                            <div class="c-link__arrow">
                              <svg class="c-icon-fill"><use href="../../../../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                            </div>
                        </a>
                    </div>
                    <div class="col-5">
                        <a class="c-link c-link--sm" href="#">
                            <div class="c-link__text">www.havasgroup.com</div>
                            <div class="c-link__arrow">
                              <svg class="c-icon-fill"><use href="../../../../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</>
		)

}