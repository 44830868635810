import React from 'react';

export default function AssetsWeekContainer () { 
	return(
		<>
		<section class="l-section l-section--secondary">
  <div class="container">
    <div class="l-section__header l-section__header_center">
      <div class="text-center">
        <h4>Asset of the Week</h4>
        <h2 class="h1 l-section__title">Dron footage of New York City</h2>
      </div>
    </div>
     <div class="c-asset-featured">
    <div class="c-asset-featured__media">
        <div class="ratio ratio-16x9">
            <img src={require("../../../assets/images/img-media.jpg")} alt="" />
        </div>
        <div class="c-btn-fab">
            <svg class="c-icon-fill c-icon--56"><use href="../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
        </div>
        <span class="c-pill c-pill--yellow-100">Pill</span>
    </div>
    <div class="c-asset-featured__content">
      <div class="row g-5 g-xxl-10">
        <div class="col-xl-4 order-xl-2">
            <div class="row">
                <div class="col-md-6 col-xl-12 order-xl-2">
                    <div class="d-flex mt-xl-3 mb-3 mb-md-0">
                        <button type="button" class="c-btn c-btn-outline--primary c-btn-icon">
                            Detail
                            <svg class="c-icon-fill"><use href="../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                        </button>
                        <button type="button" class="c-btn c-btn-outline--primary c-btn-icon">
                            Open
                            <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-open-v1"/></svg>
                        </button>
                    </div>
                </div>
                <div class="col-md-6 col-xl-12 order-xl-1">
                    <button type="button" class="c-btn c-btn--primary c-btn-icon c-btn-icon__center w-100">
                        <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-download-v1"/></svg>
                        Download
                    </button>
                </div>              
            </div>
        </div>
        <div class="col-xl-8 order-xl-1">
            <div class="row g-5 g-xxl-10">
                <div class="col-md-6 col-xl-4 d-flex">
                    <svg class="c-icon-stroke me-3 mt-1"><use href="../assets/icons/sprite-icons.svg#icon-bookmark-asset-v1"/></svg>
                    <div>
                        <div class="c-data">From: <span>Havas New York</span></div>
                        <div class="c-signature_2">Edited: <span>Katy Hoffman</span></div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-8">
                    <div class="c-data mb-2">Created: <span>April, 14 2022</span></div>
                    <p class="lead">Instantly make your content more dynamic and frictionless with amazing 4K/HD drone videos. Shot with Industry-Leading Cameras with cooperation with Panavision.</p>        
                </div>
            </div>
        </div>
      </div>    
    </div>
</div>
  </div>
</section>
</>
		)

}