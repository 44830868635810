import React from 'react';
import { default as logo } from '../../../../assets/images/havas-group-logo.svg';

//components


export default function NavbarContainer () { 
	return(
		<header className="l-header">
  <div className="l-header__left">
    <div className="l-header__logo">
      <img src={logo} alt="" />
    </div>
    <div className="l-header__brand">
      <h3>Agora<span>3.0</span></h3>
    </div>
  </div>
  <div className="l-header__center">
    <div className="c-nav">
      <div className="c-nav__item active">Item</div>
      
      <div className="c-nav__item">Item</div>
      
      <div className="c-nav__item">Item</div>
      
      <div className="c-nav__item">Item</div>
      
      <div className="c-nav__item">Item</div>
      
    </div>
  </div>
  <div className="l-header__right">
    <div className="l-header__block">
      <svg className="c-icon-stroke c-icon--32"><use href={"../../../../../assets/icons/sprite-icons.svg#icon-bookmark-v1"}/></svg>
      <div className="c-badge">15</div>
    </div>
    <div className="l-header__block">
      <div className="c-avatar">
        <div className="c-avatar__img">
        <img src={require("../../../../assets/images/avatar.jpg")} alt="" />
        </div>
      </div>  
    </div>
  </div>
</header>

		)

}