import React from 'react';

export default function LibraryContainer () { 
	return(
		<>
		<section class="l-section l-section--secondary">
  <div class="container">
    <div class="l-section__header">
      <div class="row w-100">
        <div class="col-12 col-sm-auto">
          <h2 class="h3 bold l-section__title">Documents for you</h2>
        </div>
        <div class="col">
          <div class="dropdown ms-sm-8">
            <label>Sort:</label>
            <a class="c-link dropdown-toggle" href="#" role="button" id="dropdownMenuRecommended" data-bs-toggle="dropdown" aria-expanded="false">
              <div class="c-link__text">Dropdown</div>
              <div class="c-link__arrow">
                <svg class="c-icon-fill"><use href="../assets/icons/sprite-icons.svg#icon-arrow-down"/></svg>
              </div>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuRecommended">
              <li><a class="dropdown-item" href="#">Option 01</a></li>
              <li><a class="dropdown-item" href="#">Option 02</a></li>
              <li><a class="dropdown-item" href="#">option 03</a></li>
            </ul>
          </div>
        </div>
        <div class="col-auto">
          <a class="c-link" href="#">
            <div class="c-link__text">Link</div>
            <div class="c-link__arrow">
              <svg class="c-link__arrow__line"><line x1="0" y1="0" x2="36" y2="0" stroke="black" /></svg>
              <svg class="c-icon-fill"><use href="../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="row g-5 row-cols-xs-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4" >
      
      <div class="col">
        <div class="c-card-assets">
    <div class="c-card-assets__media">
        <img src={require("../../../assets/images/card-news.jpg")} alt="" />
        <div class="c-card-assets__header">
            <span class="c-pill c-pill--secondary">Pill</span>
            <div class="c-save c-save--white active">
                <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-bookmark-asset-v1"/></svg>
            </div>
        </div>
    </div>
    <div class="c-card-assets__content">   
        <div class="c-card-assets__body">
            <div class="c-signature_1">Katy Hoffman</div>
            <h5 class="c-card-assets__title">Havas Group Credentials Paris 2022</h5>
            <div class="c-data">Created: <span>Jun 18, 2022</span></div>
        </div>
        <div class="c-card-assets__footer">
            <div class="d-flex mb-2">
                <button type="button" class="c-btn c-btn-outline--primary c-btn-icon">
                    Detail
                    <svg class="c-icon-fill"><use href="../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                </button>
                <button type="button" class="c-btn c-btn-outline--primary c-btn-icon">
                    Open
                    <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-open-v1"/></svg>
                </button>
            </div>
            <button type="button" class="c-btn c-btn--primary c-btn-icon c-btn-icon__center w-100">
                <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-download-v1"/></svg>
                Download
            </button>
        </div>
    </div>
</div>
      </div>
      
      <div class="col">
        <div class="c-card-assets">
    <div class="c-card-assets__media">
        <img src={require("../../../assets/images/card-news.jpg")} alt="" />
        <div class="c-card-assets__header">
            <span class="c-pill c-pill--secondary">Pill</span>
            <div class="c-save c-save--white active">
                <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-bookmark-asset-v1"/></svg>
            </div>
        </div>
    </div>
    <div class="c-card-assets__content">   
        <div class="c-card-assets__body">
            <div class="c-signature_1">Katy Hoffman</div>
            <h5 class="c-card-assets__title">Havas Group Credentials Paris 2022</h5>
            <div class="c-data">Created: <span>Jun 18, 2022</span></div>
        </div>
        <div class="c-card-assets__footer">
            <div class="d-flex mb-2">
                <button type="button" class="c-btn c-btn-outline--primary c-btn-icon">
                    Detail
                    <svg class="c-icon-fill"><use href="../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                </button>
                <button type="button" class="c-btn c-btn-outline--primary c-btn-icon">
                    Open
                    <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-open-v1"/></svg>
                </button>
            </div>
            <button type="button" class="c-btn c-btn--primary c-btn-icon c-btn-icon__center w-100">
                <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-download-v1"/></svg>
                Download
            </button>
        </div>
    </div>
</div>
      </div>
      
      <div class="col">
        <div class="c-card-assets">
    <div class="c-card-assets__media">
        <img src={require("../../../assets/images/card-news.jpg")} alt="" />
        <div class="c-card-assets__header">
            <span class="c-pill c-pill--secondary">Pill</span>
            <div class="c-save c-save--white active">
                <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-bookmark-asset-v1"/></svg>
            </div>
        </div>
    </div>
    <div class="c-card-assets__content">   
        <div class="c-card-assets__body">
            <div class="c-signature_1">Katy Hoffman</div>
            <h5 class="c-card-assets__title">Havas Group Credentials Paris 2022</h5>
            <div class="c-data">Created: <span>Jun 18, 2022</span></div>
        </div>
        <div class="c-card-assets__footer">
            <div class="d-flex mb-2">
                <button type="button" class="c-btn c-btn-outline--primary c-btn-icon">
                    Detail
                    <svg class="c-icon-fill"><use href="../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                </button>
                <button type="button" class="c-btn c-btn-outline--primary c-btn-icon">
                    Open
                    <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-open-v1"/></svg>
                </button>
            </div>
            <button type="button" class="c-btn c-btn--primary c-btn-icon c-btn-icon__center w-100">
                <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-download-v1"/></svg>
                Download
            </button>
        </div>
    </div>
</div>
      </div>
      
      <div class="col">
        <div class="c-card-assets">
    <div class="c-card-assets__media">
        <img src={require("../../../assets/images/card-news.jpg")} alt="" />
        <div class="c-card-assets__header">
            <span class="c-pill c-pill--secondary">Pill</span>
            <div class="c-save c-save--white active">
                <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-bookmark-asset-v1"/></svg>
            </div>
        </div>
    </div>
    <div class="c-card-assets__content">   
        <div class="c-card-assets__body">
            <div class="c-signature_1">Katy Hoffman</div>
            <h5 class="c-card-assets__title">Havas Group Credentials Paris 2022</h5>
            <div class="c-data">Created: <span>Jun 18, 2022</span></div>
        </div>
        <div class="c-card-assets__footer">
            <div class="d-flex mb-2">
                <button type="button" class="c-btn c-btn-outline--primary c-btn-icon">
                    Detail
                    <svg class="c-icon-fill"><use href="../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
                </button>
                <button type="button" class="c-btn c-btn-outline--primary c-btn-icon">
                    Open
                    <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-open-v1"/></svg>
                </button>
            </div>
            <button type="button" class="c-btn c-btn--primary c-btn-icon c-btn-icon__center w-100">
                <svg class="c-icon-stroke"><use href="../assets/icons/sprite-icons.svg#icon-download-v1"/></svg>
                Download
            </button>
        </div>
    </div>
</div>
      </div>
      
    </div>
      
  </div>
</section>
		</>

		)

}