import React from 'react';

export default function NewsLetterContainer () { 
	return(
		<>
			<section class="l-section-cta l-section--secondary">
  <div class="container text-center">
    <div class="l-section__header l-section__header_center">
      <div class="text-center">
        <div class="mb-4">
          <span class="c-pill c-pill--yellow">Agora news</span>
        </div>
        <h2 class="h1 l-section__title mb-4">Do you want best assets<br />every month?</h2>
        <p class="lead">We don’t spam! Only best assets by Agora team.</p>
      </div>
    </div>
   <div class="row">
    <div class="col-lg-10 col-xl-8 mx-auto">
      
      <div class="c-input input-group input-group-lg mb-4">
        <input type="text" class="form-control" placeholder="Write your email..." aria-label="Write your email..." />
        <button type="button" class="c-btn c-btn--primary c-btn--lg px-6">Sign up</button>
      </div>
      
      <div class="d-inline-block">
        <a class="c-link c-link--sm" href="#">
          <div class="c-link__text">Customize e-mail preferences</div>
          <div class="c-link__arrow">
            
            <svg class="c-icon-fill"><use href="../assets/icons/sprite-icons.svg#icon-arrow-right"/></svg>
          </div>
        </a>
      </div>
    </div>
   </div>      
  </div>
</section>
		</>

		)

}