import React from 'react';

export default function HeaderContainer () { 
	return(
		<>
    <div class="main">
		<section className="l-section-hero">
  <div className="l-section-hero__bg">
    <img src={require('../../../assets/images/bg-hero.jpg')} alt="" />
  </div>
  <div className="container">
    <div className="row w-100">
      <div className="col-lg-10 col-xl-8 mx-auto">
        <h1 className="mb-5">What are you<br /> looking for today?</h1>
        <div className="c-search input-group input-group-lg">
    <input type="text" className="form-control" placeholder="Search in Agora for documents, people or..." aria-label="Search in Agora for documents, people or..." />
    <svg className="c-search__icon c-icon-stroke c-icon--40"><use href={"assets/icons/sprite-icons.svg#icon-magnifier"}/></svg>
    <div className="c-search__dropdown dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
        <div>
            <span>Asset type</span>
            <svg className="c-icon-fill c-icon--20"><use href={"../../../assets/icons/sprite-icons.svg#icon-arrow-down"}/></svg>
        </div>
    </div>
    <ul className="dropdown-menu dropdown-menu-end">
        <li><a className="dropdown-item" href="#">Images</a></li>
        <li><a className="dropdown-item" href="#">Video</a></li>
        <li><a className="dropdown-item" href="#">Sound</a></li>
    </ul>
    <button type="button" className="c-btn c-btn--primary c-btn--lg">
        <span>Search</span>
        <svg className="c-icon-stroke c-icon--36"><use href={"../../../assets/icons/sprite-icons.svg#icon-magnifier"}/></svg>
    </button>
</div>
      </div>
    </div>
  </div>
</section>


<section className="l-section-links l-section pt-0">
  <div className="container">
    <div className="row g-5 g-xxl-10 row-cols-xs-1 row-cols-sm-2 row-cols-lg-3">
      
        <div className="col">

<div className="c-card-box">
  <div className="c-card-box__body">
    <h4 className="c-card-box__title">News & Communications</h4>
    <div className="c-card-box__content">
      <p className="text01">Latest news and articles from our agency</p>
    </div>
  </div>
  <div className="c-card-box__footer">
    <a className="c-link" href="#">
      <div className="c-link__text">Link</div>
      <div className="c-link__arrow">
        <svg className="c-link__arrow__line"><line x1="0" y1="0" x2="36" y2="0" stroke="black" /></svg>
        <svg className="c-icon-fill"><use href={"../../../assets/icons/sprite-icons.svg#icon-arrow-right"}/></svg>
      </div>
    </a>
  </div>
  
</div>

        </div>
      
        <div className="col">
          
<div className="c-card-box">
  <div className="c-card-box__body">
    <h4 className="c-card-box__title">News & Communications</h4>
    <div className="c-card-box__content">
      <p className="text01">Latest news and articles from our agency</p>
    </div>
  </div>
  <div className="c-card-box__footer">
    <a className="c-link" href="#">
      <div className="c-link__text">Link</div>
      <div className="c-link__arrow">
        <svg className="c-link__arrow__line"><line x1="0" y1="0" x2="36" y2="0" stroke="black" /></svg>
        <svg className="c-icon-fill"><use href={"../../../assets/icons/sprite-icons.svg#icon-arrow-right"}/></svg>
      </div>
    </a>
  </div>
  
</div>

        </div>
      
        <div className="col">
          
<div className="c-card-box">
  <div className="c-card-box__body">
    <h4 className="c-card-box__title">News & Communications</h4>
    <div className="c-card-box__content">
      <p className="text01">Latest news and articles from our agency</p>
    </div>
  </div>
  <div className="c-card-box__footer">
    <a className="c-link" href="#">
      <div className="c-link__text">Link</div>
      <div className="c-link__arrow">
        <svg className="c-link__arrow__line"><line x1="0" y1="0" x2="36" y2="0" stroke="black" /></svg>
        <svg className="c-icon-fill"><use href={"../../../assets/icons/sprite-icons.svg#icon-arrow-right"}/></svg>
      </div>
    </a>
  </div>
  
</div>

        </div>
      
        <div className="col">
          
<div className="c-card-box">
  <div className="c-card-box__body">
    <h4 className="c-card-box__title">News & Communications</h4>
    <div className="c-card-box__content">
      <p className="text01">Latest news and articles from our agency</p>
    </div>
  </div>
  <div className="c-card-box__footer">
    <a className="c-link" href="#">
      <div className="c-link__text">Link</div>
      <div className="c-link__arrow">
        <svg className="c-link__arrow__line"><line x1="0" y1="0" x2="36" y2="0" stroke="black" /></svg>
        <svg className="c-icon-fill"><use href={"../../../assets/icons/sprite-icons.svg#icon-arrow-right"}/></svg>
      </div>
    </a>
  </div>
  
</div>

        </div>
      
        <div className="col">
          
<div className="c-card-box">
  <div className="c-card-box__body">
    <h4 className="c-card-box__title">News & Communications</h4>
    <div className="c-card-box__content">
      <p className="text01">Latest news and articles from our agency</p>
    </div>
  </div>
  <div className="c-card-box__footer">
    <a className="c-link" href="#">
      <div className="c-link__text">Link</div>
      <div className="c-link__arrow">
        <svg className="c-link__arrow__line"><line x1="0" y1="0" x2="36" y2="0" stroke="black" /></svg>
        <svg className="c-icon-fill"><use href={"../../../assets/icons/sprite-icons.svg#icon-arrow-right"}/></svg>
      </div>
    </a>
  </div>
  
</div>

        </div>
      
        <div className="col">
          
<div className="c-card-box">
  <div className="c-card-box__body">
    <h4 className="c-card-box__title">News & Communications</h4>
    <div className="c-card-box__content">
      <p className="text01">Latest news and articles from our agency</p>
    </div>
  </div>
  <div className="c-card-box__footer">
    <a className="c-link" href="#">
      <div className="c-link__text">Link</div>
      <div className="c-link__arrow">
        <svg className="c-link__arrow__line"><line x1="0" y1="0" x2="36" y2="0" stroke="black" /></svg>
        <svg className="c-icon-fill"><use href={"../../../assets/icons/sprite-icons.svg#icon-arrow-right"}/></svg>
      </div>
    </a>
  </div>
  
</div>

        </div>
      
    </div>
  </div>
</section>
</div>
</>


		)

}