import logo from './logo.svg';
import './App.css';
import './theme/theme.css';

//Components
import HomeComponent from './app/views/home/index.jsx';

function App() {
  return (
    <>
      <HomeComponent />
    </>
  );
}

export default App;
